import React from 'react'

import Layout from '../components/layout'
import Logo from '../components/logo'
import SEO from '../components/seo'

import './index.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Lona" />
    <Logo />
    <span className="download">
      <a
        className="link"
        href="https://lona.design/releases/LonaStudio_1.0.11.dmg"
      >
        Download
      </a>{' '}
      for Mac
    </span>
  </Layout>
)

export default IndexPage
